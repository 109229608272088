<template>
   <el-dialog 
   class="dialog-3" 
   :width="popupWidth"
   :visible.sync="visible"
   @open="handleOpen"
   >
      <slot name="content">
          <p>内容插槽</p>
      </slot>
      <span slot="footer" class="dialog-3-footer">
          <slot name="footer">
            <p>底部插槽</p>
          </slot>
      </span>
    </el-dialog>
</template>

<script>
export default {
    name: "myDialog3",
    props: {
      // 弹窗隐藏参数
      centerDialogVisible: {
        type: Boolean,
        default() {
          return false;
        }
      },
      // 弹窗默认宽度
      popupWidth: {
        type: String,
        default() {
          return "420px";
        }
      }
    },
     data() {
      return {
      }
    },
    computed: {
      // 计算弹窗是否隐藏
      visible: {
        // 获取初始值centerDialogVisible
        get() {
          return this.centerDialogVisible;
        },
        // 重新计算visible值
        set(val) { 
          this.$emit("updateVisible", val);
        }
      },
    },
    methods: {
      handleOpen() {
        this.$emit("openDialog");
      }
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  background-color: #FFFFD1;
    border-radius: 7px;
}
/deep/ .el-dialog__title {
    display: none;
}
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__headerbtn {
  display: none;
}
/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #000;
}
/deep/ .el-dialog__body {
  padding: 10px;
}
/deep/ .el-dialog__footer {
    padding: 30px;
    display: flex;
    justify-content: center;
}
/deep/ .dialog-3-footer {
    .footer-btn {
        width: 90px;
        height: 27px;
        background-image: none;
        border: none;
    }
    .btn1 {
        background-color: #F58B37;
    }
    .btn2 {
        color: #fff;
        background-color: #999999;
    }
}
// 打印发货单弹窗样式
/deep/ .el-form {
    font-size: 12px;
}
/deep/ .el-form-item {
    margin-bottom: 0;
}
/deep/ .el-form-item__label {
    font-size: 12px;
    color: #3E566A;
    line-height: 30px;
}
/deep/ .el-form-item__content {
    line-height: 30px;
    font-size: 12px;
}
/deep/ .fahuo-print-dialog {
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/deep/ .express-print-dialog {
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/deep/ .el-select-dropdown__item {
  font-size: 12px;
}
/deep/ .consigner-dialog {
    height: 130px;
    display: flex;
    flex-direction: column;
    padding: 50px;
}
</style>