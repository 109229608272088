<template>
   <el-dialog 
   class="dialog-4" 
   :visible.sync="visible">
        <div class="dialog-4-header">
          <!-- header插槽 -->
          <slot name="header">
            <span class="header-title">{{dialogTitle}}</span>
            <div class="header-btns">
                <el-button class="header-btn" size="mini" @click="Save">保存</el-button>
                <el-button class="header-btn" size="mini" @click="Cancel">取消</el-button>
            </div>
          </slot>
        </div>
        <div class="dialog-4-content">
          <slot name="content">
            <p>弹窗内容自定义</p>
          </slot>
        </div>
    </el-dialog>
</template>

<script>
export default {
  name: "myDialog4",
  props: {
    // 弹窗标题
    dialogTitle: {
      type: String,
      default: "标题"
    },
    // 弹窗隐藏参数
    centerDialogVisible: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    // 计算弹窗是否隐藏
    visible: {
      // 获取初始值centerDialogVisible
      get() {
        return this.centerDialogVisible;
      },
      // 重新计算visible值
      set(val) { 
        this.$emit("updateVisible", val);
      }
    }
  },
  methods: {
    // 取消按钮函数
    Cancel() {
      this.$emit("resetPopupData");
    },
    // 保存修改按钮函数
    Save() {
      this.$emit("submitPopupData");
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  border: none;
  box-sizing: content-box;
  box-shadow: 0 0 0px 5px #999999;
  width: 700px;
  height: 500px;
}
/deep/ .el-dialog__header {
  display: none;
}
/deep/ .el-dialog__body {
  height: 100%;
  padding: 0;
  background-color: #4E5159;
}
.dialog-4-header {
  padding: 10px 15px;
  background-color: #333333;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 13px;
  font-weight: 1000;
  .header-btn {
      background-image: none;
      background-color: #00BB9C;
      border: none;
      padding: 5px 30px;
      color: #fff;
  }
}
.dialog-4-content {
  padding: 10px 30px;
  color: #fff;
  font-size: 12px;
}
</style>