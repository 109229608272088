<template>
   <el-dialog 
   class="dialog-2" 
   @opened="handleOpen"
   :visible.sync="visible">
        <slot>
            <p>主体内容</p>
        </slot>
        <div class="out-menu">
            <!-- <el-button class="menu-btn btn1" @click="doPrint">现在打印</el-button> -->
            <!-- <el-button class="menu-btn btn2" >编辑模板</el-button>
            <el-dropdown>
            <el-button class="menu-btn btn2" >导出为WPSPDF<i class="el-icon-arrow-down el-icon--right"></i></el-button>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>选项1</el-dropdown-item>
                <el-dropdown-item>选项2</el-dropdown-item>
            </el-dropdown-menu>
            </el-dropdown> -->
        </div>
    </el-dialog>
</template>

<script>

export default {
    name: "myDialog2",
    props: {
      // 弹窗隐藏参数
      centerDialogVisible: {
        type: Boolean,
        default() {
          return false;
        }
      }
    },
    computed: {
      // 计算弹窗是否隐藏
      visible: {
        // 获取初始值centerDialogVisible
        get() {
          return this.centerDialogVisible;
        },
        // 重新计算visible值
        set(val) { 
          this.$emit("updateVisible", val);
        }
      },
    },
    methods: {
      handleOpen() {
        this.$emit("openDialog");
      },
      // 打印函数
      doPrint() {
        this.$emit("print");
      },
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
    width: fit-content;
}
/deep/ .el-dialog__header {
    padding: 0;
    width: 0;
}
/deep/ .el-dialog__title {
    display: none;
}
/deep/ .el-dialog__headerbtn {
    position: absolute;
    top: -20px;
    right: 0px;
    height: 15px;
    width: 15px;
    background: #E79B25;
    border-radius: 50%;
    font-size: 12px;
}
/deep/ .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
}
/deep/ .el-dialog__body {
    padding: 0;
    color: #B8CDDD;
    height: 560px;
}
/deep/ .el-tabs--border-card>.el-tabs__content {
    padding: 0;
}
.out-menu {
  height: 120px;
  width: 120px;
  position: absolute;
  right: -120px;
  top: 20px;
  .menu-btn {
    width: 100%;
    margin: 1px 0;
    border-radius: 0px 5px 5px 0px;
    font-weight: 1000;
    padding: 5px;
  }
  .btn1 {
    height: 50px;
    font-size: 14px;
    background-image: -webkit-linear-gradient(bottom,#FDCF46 ,#FFEF8F);
    border: 1px solid #FDCF46;
  }
  .btn2 {
    font-size: 12px;
    height: 40px;
  }
}
// 发货单弹窗样式
/deep/ .fahuo-dialog {
  display: flex;
  flex-direction: row;
  .left-iframe {
    width: 800px;
    background: #fff;
  }
  .right-menu {
    height: 560px;
    width: 150px;
    background-color: #4E5159;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .menu-btn1 {
      position: absolute;
      top: 10px;
      background-image: none;
      background-color: #4E5159;
      color: #fff;
      border: 1px solid #656667;
    }
    .radio-list {
      width: 80%;
      margin-bottom: 20px;
      .radio-item {
        margin: 8px 0;
      }
    }
    .menu-btn2 {
      padding: 5px 20px;
    }
  }
}
/deep/ .el-radio{
  color: #959595;
}
/deep/ .el-radio__label {
    font-size: 12px;
}
/deep/ .el-radio__input.is-checked+.el-radio__label {
  color: #fff;
}
// 快递单弹窗样式
/deep/ .lable-btn {
  color: #B7CBDB;
}
</style>