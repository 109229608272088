<template src="./index.html"></template>
<style src="./index.less" lang="less" scoped></style>
<<script>
export default {
  name: "GetExpressErrorPopup",
  data(){
    return{
      dialogShow11: true,
    }
  },
  props:{
    dataSrc:{},
  },
  methods:{
    closePopup(){
      this.$emit("closePopup")
    }
  }
}
</script>